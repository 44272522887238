//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.fix-design {
  overflow: auto;
  height: 100vh;
}
.hover:hover span {
  color: white !important;
}
a.menu-link.without-sub:hover span {
  color: white !important;
}
a.menu-link.without-sub.active {
  background-color: white !important;
}
a.menu-link.without-sub.active span:last-child {
  color: #15ac8e !important;
}
a.menu-link.without-sub.active .bullet-dot {
  background-color: #15ac8e !important;
}
#dropdown-basic {
  color: #7e8299 !important;
}
#dropdown-basic {
  background-color: #f5f8fa !important;
}
// input[type='date']::-webkit-calendar-picker-indicator {
//   background: transparent;
//   bottom: 0;
//   color: transparent;
//   cursor: pointer;
//   height: auto;
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: auto;
// }
// .form-select {
//   padding: 0.5rem 3rem 0.5rem 1rem !important;
// }
// .form-control {
//   padding: 0.5rem 3rem 0.5rem 1rem !important;
// }
.form-select.form-select-solid {
  color: #a1a5b7 !important;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.abc {
  .svg-icon.svg-icon-warning {
    color: var(--kt-text-warning) !important;
    svg {
      border: 1px solid var(--kt-text-warning);
      border-radius: 50%;
    }
  }
}
.svg-icon.svg-icon-2x svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.otp-input {
  input {
    width: 3em !important;
    text-align: center;
    height: 3em;
  }
}

.svg-icon-wrapper {
  position: relative;
  span {
    color: rgb(255, 199, 0);
    margin-left: 0px !important;
    border-radius: 50%;
    background-color: rgba(255, 199, 0, 0.19);
    position: absolute;
    right: -20px;
    top: 7px;
  }
}

.contact-details-wrapper {
  justify-content: unset;
}
.subtenant-mobile-wrapper {
  display: inline-block;

  .react-tel-input {
    display: inline-block;

    input {
      display: inline-block;
    }
  }
}

.select-box {
  border-radius: 5px;
  background-color: #19ffbe1c;
  box-shadow: 0px 6px 20px 10px rgb(76 87 125 / 8%);
}
.video_btn {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
}

.new_add {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.scr {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 20px;
  height: 80px;
}

.drag-area {
  border: 2px dashed #000;
  height: 50px;
  width: 350px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.active {
    border: 2px solid #000;
  }
  .icon {
    font-size: 100px;
    color: #000;
  }
  header {
    font-size: 30px;
    font-weight: 500;
    color: #000;
  }
  span {
    font-size: 25px;
    font-weight: 500;
    color: #000;
    margin: 10px 0 15px 0;
  }
  button {
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    background: #000;
    color: #5256ad;
    border-radius: 5px;
    cursor: pointer;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.text-muted-1fr {
  display: grid !important;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 7px 0;
}
.text-muted {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 7px 0;
}
.gray_color {
  color: #a1a5b7;
}
.text-muted-1-2-fr {
  display: grid !important;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 7px 0;
}
.text-auto-fr {
  display: grid !important;
  grid-template-columns: auto 1fr;
  align-items: center !important;
  gap: 1rem;
  padding: 7px 0;
}
.make_grid {
  display: grid !important;
  grid-template-columns: 1fr auto;
  align-items: center !important;
  gap: 1rem;
  padding: 7px 0;
}

.grid-auto {
  display: grid !important;
  grid-template-columns: auto auto;
  align-items: center !important;
  gap: 7px;
}

.grid-auto-new {
  width: 100%;
  display: grid !important;
  grid-template-columns: auto auto;
  align-items: flex-start !important;
  justify-content: space-between;
  gap: 10px;
}

.grid-out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center !important;
  column-gap: 2rem;
  row-gap: 2rem;
}
.grid-out-diff-gap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center !important;
  column-gap: 2rem;
  row-gap: 1rem;
}

.grid_3_col {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center !important;
  column-gap: 2rem;
  row-gap: 1rem;
}
.grid_3_col_1fr {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center !important;
  column-gap: 2rem;
  row-gap: 1.5rem;
}
.borderBottom {
  border-bottom: 1px dashed #aaaaaa50;

  &:last-child {
    border: none;
  }
}
.grid_big_col {
  grid-template-columns: 2fr 3fr !important;
}
.tops {
  grid-column: 1/-1;
}
.side {
  height: 100%;
}
.designedInput {
  background: #aaaaaa20;
  color: black;
  padding: 10px;
  box-shadow: rgb(134 135 135 / 13%) 0px 7px 13px 3px;
  /* border-radius: 3px; */
  border-bottom: 2px solid #000;
}
// .border-outline {
//   box-shadow: rgb(134 135 135 / 13%) 0px 0px 13px 3px;
//   padding: 11px;
//   border-radius: 12px;
// }
.flex--between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment {
  grid-template-columns: 1fr;
}

.green_color, .d-tabs {
  color: #007a59 !important;
}
.br-5 {
  border-radius: 5px;
}
.br-10 {
  border-radius: 10px;
}
.dot {
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
.greens {
  border-radius: 50%;
  background-color: green;
}
// .gray {
//   border-radius: 50%;
//   background-color: #aaa;
// }

.small_grey {
  font-size: 14px;
  color: #aaa;
}

.reds {
  background-color: red;
}

.yellows {
  background-color: yellow;
}

.min_height {
  min-height: 250px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnss {
  color: #fff;
  background-color: #007a59;
  border-radius: 8px;
  font-size: 14px;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  opacity: 0;
}

.value_black {
  color: #000 !important;
}

.img_link {
  position: relative;
  width: 100%;

  .img_preview {
    max-width: 100%;
    height: auto;
    cursor: none;
    transition: all 0.3s ease;
    &:hover {
      filter: blur(1px);
    }
  }

  .view_btn {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1111;
    animation: sleeps 0.3s ease forwards;
  }

  @keyframes sleeps {
    0% {
      // transform: translateY(-1000%);
      opacity: 0;
    }
    100% {
      opacity: 1;
      // transform: translateY(0%);
    }
  }
}

.img_link_big {
  position: relative;
  width: 100%;

  .img_preview_small {
    max-width: 30%;
    height: auto;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.img--box--style {
  box-shadow: 0px 0px 11px 1px #8484845e;
  padding: 9px;
  border-radius: 7px;
}

.img-sets {
  position: relative;
  .main_img {
    border-radius: 5px;
  }

  .closered {
    display: none;
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
  }
  &:hover .closered {
    display: block;
  }
}

.img_link {
  width: 100%;

  .big_screen_img {
    border-radius: 10px;
    width: 100px;
    height: 100px;
  }
}

.note-gap {
  gap: 1rem;
  padding: 0;
}
.w-275px {
  width: 28rem !important;
}
.notification {
  overflow-y: scroll;
  max-height: 27rem;
}

.msg_title {
  color: #fff;
  background-color: #007a59;
}

.lis {
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color: #007a5910;
  }
}

.ring-user {
  padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid #000;
}
.ring-user-white {
  padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid #fff;
}
.ring {
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  // padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid #000;
}

.cus-line {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.msg {
  width: 60%;
}
.rec_msg {
  background-color: #aaa;
}
.send_msg {
  background: #007a5910;
}

.msg_icon {
  position: relative;
  cursor: pointer;
}

.message {
  position: absolute;
  bottom: 0;
  top: 70px;
  right: 115px;
  animation: Opt 0.3s ease;
}
@keyframes Opt {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.msg_title {
  padding: 14px;
  background-color: #007a59;
  color: #fff !important;
}
.accordion-button:not(.collapsed) {
  font-size: 1rem;
  background-color: #007a59;
  color: #fff;
}

.msg_search {
  position: relative;
}

.filter {
  cursor: pointer;
  position: absolute;
  right: 7%;
  top: 14%;
}
.chat_filter {
  cursor: pointer;
}

.filter_drop {
  position: absolute;
  right: 4%;
  top: 20%;
  width: 120px;
  z-index: 1;
  box-shadow: 0 0 15px #aaa;
  animation: Opt 0.3s ease;

  li {
    list-style: none;
    &:hover {
      cursor: pointer;
      background-color: #a1a1a11f;
    }
  }
}
.chat_filter_drop {
  position: absolute;
  right: 5px;
  top: 50px;
  width: 120px;
  z-index: 1;
  box-shadow: 0 0 15px #aaa;
  animation: Opt 0.3s ease;

  li {
    list-style: none;
    &:hover {
      cursor: pointer;
      background-color: #a1a1a11f;
    }
  }
}

.flex-starts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-ends {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.act {
  background-color: #007a5910;
}

.msg_icon {
  position: relative;
  &::after {
    content: ' ';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: red;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: Opt 0.3s forwards infinite;
  }
}

.tests {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}
.shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.upload {
  width: max-content;
}

.arrow_approval_request_bottom {
  position: relative;
}
.arrow_approval_request {
  position: absolute;
  z-index: 1;
  top: -2rem;
  left: 50%;
}
.min {
  min-height: 1rem;
}

.lists {
  list-style: none;

  li {
    a {
      &:hover {
        color: #007a59;
      }
    }
  }
}

.filters_hover {
  &:hover {
    color: #fff !important;
  }
}

.rotate {
  transform: rotateX(180deg);
}

.chatback {
  position: absolute;
  width: 107%;
  height: 107%;
  z-index: 0;
  opacity: 0.1;
}

.group {
  display: flex;
  align-items: center;
  gap: -1;
}

// .tooltip-inner {
//   color: #fff;
//   background-color: #000;
// }

// .tooltip.right .tooltip-arrow {
//   top: 50%;
//   left: 0;
//   margin-top: -5px;
//   border-top: 5px solid transparent;
//   border-bottom: 5px solid red;
//   border-left: 5px solid transparent;
//   // border-right: 5px solid #007a59;
// }

.tooltip > div.tooltip-inner {
  background-color: #007a59 !important;
  color: #fff !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > div.arrow::before {
  border-top-color: red !important;
  color: red !important;
}

// .red-tooltip + .tooltip > .tooltip-arrow {
//   border-bottom-color: #f00;
// }

.add_payment_table {
  position: relative;
  width: 100%;
}
.apply_btn {
  position: absolute;
  right: -12rem;
  bottom: 1.5rem;
}

.preloaderBg {
  // position: fixed;
  z-index: 10;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
}

.preloader {
  // margin: auto;
  background-color: goldenrod;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.preloader2 {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f00;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  animation: spin 1s ease-in-out infinite;
  position: relative;
  margin: auto;
  top: 19rem;
  left: -23rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.small_font {
  font-size: 11px !important;
  padding-left: 8px !important;
  font-weight: 500;
}

.you {
  z-index: 99999 !important;
}

.modalStyle .ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
  overflow: hidden !important;
}
.modalStyle .ant-modal-footer {
  border-radius: 0 0 20px 20px !important;
}

.ant-modal {
  border-radius: 10px !important;
  /* Only border-radius here */
}

.ant-modal-content {
  border-radius: 10px !important;
  /* border: 10px solid black; */
}
