.mainRequestBox {
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-basis: content;
  padding: 20px 15px;
  text-align: center;
  border: 2px solid #146c6a !important;
  cursor: pointer;
  width: 140px;
}
.mainRequestBoxColor {
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-basis: content;
  padding: 20px 15px;
  text-align: center;
  // border: 1px solid #146C6A !important;
  background-color: #146c6a;
  color: #ffffff !important;
  width: 140px;
}

.headTextWhite {
  color: #ffffff !important;
}

.requestBoxOpacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 14px;
}

.urgency-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px !important;
  height: 21px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.urgencyStandardStatus {
  background-color: #b0b7c3 !important;
}

.urgencyEmergencyStatus {
  background-color: #d54645 !important;
}

.schedule .ant-modal-header {
  border-bottom: 0px solid !important;
}

.schedule .ant-modal-close-x {
  display: none !important;
}

.schedule .ant-modal-footer {
  border-top: 0px solid #f0f0f0;
  padding-bottom: 5px !important;
}

.schedule .MuiButtonBase-root {
  padding-right: 4px !important;
}

.schedule .MuiInputBase-input {
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  border-right: 0px solid !important;
  color: #384a62 !important;
}
.schedule .MuiInputBase-input:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
  border-right: 0px solid !important;
}

.schedule .MuiTextField-root {
  border: 0.1rem solid #bec3cb !important;
}

.btn-fit-content {
  width: fit-content !important;
  height: 31px !important;
}

.btn-fit-content:hover {
  width: fit-content !important;
  height: 31px !important;
}

.status-w-100 {
  width: 100px !important;
}

.status-w-100:hover {
  width: 100px !important;
}

.jobs-comments {
  border-radius: 5px !important;
  border: 0.12rem solid #384a62 !important;
  background: #fff !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}
.jobs-comments-edit {
  /* no value in input */
  border-radius: 5px !important;
  border: 0.1rem solid #bec3cb !important;
  border-radius: 2px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}

.green-border-box {
  border-radius: 5px !important;
  border: 0.12rem solid #146c6a !important;
  background: #fff !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}

.red-filled-btn {
  border: 2px solid #d54645 !important;
  color: white !important;
  background-color: #d54645 !important;
  // width: 170px !important;
  font-size: 12px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.report-active {
  font-weight: 800;
}

.report-text {
  word-wrap: break-word;
  width: 100px;
  text-align: center;
  padding: 8px 5px;
}

.report .form-check-custom.form-check-solid .form-check-input {
  border-color: #146c6a !important;
}

.report .form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #146c6a !important;
}

.report-confirm-button {
  width: 130px;
  height: 31px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.report-deny-button {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 130px;
  height: 31px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swal2-container .swal2-html-container {
  max-height: unset !important;
}

.clear-pick {
  margin-left: -50px !important;
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-from-bottom {
  animation: slideFromBottom 0.5s ease;
}

.purchase-order-btn {
  width: 500px !important;
}

.po-w-90 {
  width: 90% !important;
}

.po-phone .react-tel-input .form-control {
  width: -webkit-fill-available !important;
}

.swal2-styled {
  transition: none !important;
  box-shadow: none !important;
}

.h-31 {
  height: 31px !important;
}

.main-status-150 {
  width: 150px !important;
}

.added-po-card {
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
  box-shadow: lightgrey 0px 3px 6px -1px !important;
}

.po-border-shadow {
  box-shadow: lightgrey 0px 5px 4px -1px !important;
}

.bg-grey {
  background-color: #f2f2f2;
}
.move-down {
  // opacity: 0.5; /* Reduce opacity */
  // transform: rotate(5deg);
  animation: move-down-animation 0.5s ease;
}

.move-up {
  // opacity: 0.5; /* Reduce opacity */
  // transform: rotate(5deg);
  animation: move-up-animation 0.5s ease;
}

@keyframes move-down-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(270px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes move-up-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-270px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// .drag-active{
//   border: 2px dashed #146c6a;
//   background-color: #bec3cb;
// }

.file-input-drag {
  height: 110px !important;
  opacity: -0.9 !important;
  transform: translate(-2px, -110px);
  width: 500px !important;
}

.file-div-drag {
  height: 110px !important;
  width: 500px !important;
  border: 1.5px solid lightgrey !important;
  border-radius: 5px !important;
}
.file-div-drag-disabled {
  height: 110px !important;
  width: 500px !important;
  border: 1.5px solid lightgrey !important;
  border-radius: 5px !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.file-card-drag {
  width: 650px;
  border: 1.5px solid lightgrey !important;
}

.po-editor-view .ql-toolbar.ql-snow {
  display: none !important;
}

.po-editor-view .ql-container.ql-snow {
  border: none !important;
  background-color: transparent !important;
}

.po-editor-view .ql-editor {
  padding: 0px !important;
  min-height: unset !important;
}

.page-break {
  page-break-after: always; /* This will force a page break after each element with the class "page-break" */
}

.main-po input[type='radio']:checked {
  background-color: #384a62;
}

.ql-editor.ql-blank:before {
  font-style: italic !important;
}

.input-container {
  width: 180px !important;
}

.input-bg-grey {
  background-color: rgb(240, 240, 240) !important;
}

.xyz.job-modl .MuiPaper-root {
  background-color: #f5f8fa !important;
}

.po-table-card {
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
  box-shadow: lightgrey 0px 5px 10px 2px !important;
}

.status-w-75 {
  width: 75px !important;
}

.status-w-25 {
  width: 25px !important;
}

.status-w-50 {
  width: 50px !important;
}

.a4-height {
  height: 1123px !important;
}

.a4-min-height {
  min-height: 1123px !important;
}

@media print {
  // .app-main{
  //   background-color: #fff !important;
  // }

  .print-header {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // background-color: #ffffff; // Add background color if needed
    // padding: 10px; // Add padding for better visibility
    // border-bottom: 2px solid #000000; // Add border for separation
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Add shadow for better visibility
    // z-index: 1000; // Ensure it's above other content

    // &::before {
    //   content: counter(page) " / " counter(pages) ;
    //   font-weight: bold;
    // }
  }

  @page {
    margin: 30px 0px 30px 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    .app-main {
      background-color: #fff !important;
    }

    @top-right {
      content: counter(page) ' / ' counter(pages) !important;
      border-top: 2px solid #434190 !important;
      font-size: 8pt !important;

      // &::before {
      //     content: counter(page) " / " counter(pages) ;
      //     font-weight: bold;
      //   }
    }
  }
}

.jobs-property.no-value .rmsc .dropdown-container {
  background-color: #fff !important;
}

.jobs-property.has-value .rmsc .dropdown-container {
  background-color: #fff !important;
}

.jobs-property .rmsc .dropdown-container {
  width: 180px !important;
}

.disable-opacity {
  opacity: 0.5 !important;
}

.green-radio-hollow {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 50%;
  border: 1.5px solid #146c6a !important;
}

.green-radio-filled {
  height: 14px;
  width: 14px;
  background-color: #146c6a;
  border-radius: 50%;
}

.tenancy-active-blue-card {
  color: #ffff;
  padding-bottom: 5px;
  height: 56px;
  width: 150px;
  font-weight: 800px;
  border-radius: 3px !important;
  cursor: pointer;
  // margin: 0px auto;
  background-color: #384a62;
}

.blue-active-filter {
  height: 2px;
  width: 133px;
  margin: 10px 6px 0 8px;
  background-color: #384a62;
}

.insights-white-card {
  min-height: 40px;
  width: 180px;
  background-color: #ffff;
  border: 1.5px solid #bec3cb;
  border-radius: 2px;
}

.booked-border {
  border-bottom: 2px solid #0099cc;
}

.active-border {
  border-bottom: 2px solid #00b8a9;
}

.danger-border {
  border-bottom: 2px solid #e46464;
}

.warning-border {
  border-bottom: 2px solid #f38513;
}

.dark-border {
  border-bottom: 2px solid #000;
}

.word-break {
  word-break: break-word !important;
}

.jobs-property .rmsc .dropdown-content .panel-content {
  background-color: #fff !important;
}


.jobs-property .rmsc .dropdown-content .panel-content .search {
  background-color: #fff !important;
}

.maintenance .tenancy-green-card{
  width: 150px !important
}

.maintenance .tenancy-active-card{
  width: 150px !important
}

.maintenance .active-filter {
  width: 137px;
}

.gray-card{
  background-color: #8c8c8c !important;
}

.crm-shadow{
  box-shadow: lightgrey 0px 1px 2px 1px !important;
}

