.xyz.nav-line-tabs .nav-item .nav-link {
  color: #000 !important;
  font-size: 14px;
}
.xyz.nav-line-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #000 !important;
  color: #000 !important;
  font-weight: 700;
  font-size: 14px;
}

.mw-1150px {
  min-width: 1150px !important;
}

.mw-1200px {
  min-width: 1200px !important;
}

.min-card {
  min-height: 310px !important;
}

.card-res {
  min-height: unset !important;
}

.blue-btn {
  border: 1px solid #35475e !important;
  color: white !important;
  background-color: #35475e !important;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.blue-btn:hover {
  border: 1px solid #35475e;
  color: white;
  background-color: #35475e;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.blue-hollow-btn {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  background-color: white !important;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.tenancy-control {
  border-radius: 0.25rem !important;
  padding: 5px 10px !important;
  height: 32px !important;
}

.test .form-check-custom.form-check-solid .form-check-input {
  border-color: #146c6a !important ;
}

.test .form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #146c6a !important;
  background-image: var(--kt-form-check-input-checked-bg-image) !important;
}

.br-arr {
  border: 2px solid #146c6a;
  height: 25px;
  width: 40px;
  border-radius: 5px;
}

.term-btn {
  border: 2px solid #d54645 !important;
  color: white !important;
  background-color: #d54645 !important;
  width: 170px !important;
  font-size: 12px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.renew-btn {
  border: 2px solid #146c6a !important;
  color: white !important;
  background-color: #146c6a !important;
  width: 170px !important;
  // max-width: 220px !important;
  font-size: 12px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.filter-control {
  border-radius: 0.1rem !important;
  padding: 3px 10px !important;
  height: 38px !important;
  border-radius: 0px !important;
}

.bullet-ic {
  height: 5px !important;
  width: 5px !important;
  background-color: #000 !important;
  border-radius: 50% !important;
}

.tenancy-green-card {
  color: #ffff;
  padding-bottom: 5px;
  height: 56px;
  width: 140px;
  font-weight: 800px;
  border-radius: 3px !important;
  cursor: pointer;
  // margin: 0px auto;
  background-color: #0d9e91;
}

.tenancy-active-card {
  color: #ffff;
  padding-bottom: 5px;
  height: 56px;
  width: 140px;
  font-weight: 800px;
  border-radius: 3px !important;
  cursor: pointer;
  // margin: 0px auto;
  background-color: #146c6a;
}

.active-filter {
  height: 2px;
  width: 125px;
  margin: 10px 6px 0 6px;
  background-color: #146c6a;
}

.upload-btn {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 135px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.upload-btn:hover {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 135px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.ok-div {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #146c6a;
}

.xyz .MuiPaper-root {
  border: 0.1rem solid #bec3cb !important;
  background-color: #f5f8fa !important;

  color: var(--kt-input-solid-color);
  transition: color 0.2s ease;
  border-radius: 0.25rem !important;
  padding: 5px 5px !important;
  height: 35px !important;
  box-shadow: none !important;
}
.xyz .MuiPaper-root:focus {
  border-color: #35475e !important;
}

.xyz .MuiInputBase-input {
  padding: 0px !important;
  color: #384a62 !important;
}

.test .msg_title {
  background-color: #146c6a !important;
}

.xyz .form-check-input {
  border-color: #384a62 !important;
}

.xyz .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #384a62 !important;
}

.progress-filter {
  width: 100%;
  height: 20px;
  background-color: #146c6a !important ;
  opacity: 0;
  position: relative;
  z-index: 999;
}

.tenancy .form-check-custom.form-check-solid .form-check-input {
  border-color: #146c6a !important ;
}

.tenancy .form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #146c6a !important;
  background-image: none !important;
}

.term-width {
  width: fit-content !important;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  height: 10px !important;
  width: 8px !important; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  // width: 50px !important
}

::-webkit-scrollbar-thumb {
  background-color: grey !important; /* Thumb color */
  border-radius: 10px; /* Rounded corners on the thumb */
  // width: 30px !important
}

.test .page-item.active .page-link {
  background-color: #35475e !important;
}

.test .react-tel-input .form-control {
  width: 100% !important;
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 13px !important;
}

.test .react-tel-input .form-control:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
  font-size: 13px !important;
}

.test .react-tel-input .flag-dropdown.open .selected-flag {
  border: 0.1rem solid #384a62 !important;
  width: 45px !important;
}

.test .react-tel-input .flag-dropdown.selected .selected-flag {
  border: 0.1rem solid #384a62 !important;
  width: 45px !important;
}

.test .react-tel-input .selected-flag:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
}

.test .rmsc {
  height: 35px !important;
}

.test .rmsc .dropdown-heading {
  height: 34px !important;
  padding-top: 7px !important;
  padding-right: 5px !important;
  align-items: unset !important;
}

.test .rmsc .dropdown-container:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
}

.has-value .rmsc .dropdown-container {
  width: 160px !important;
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  height: 35px !important;
  background-color: transparent !important;
  // padding-top: 0.8rem !important;
  // padding-bottom: 0.8rem !important;
}



.no-value .rmsc .dropdown-container {
  width: 160px !important;
  border: 0.1rem solid #bec3cb !important;
  border-radius: 2px !important;
  height: 35px !important;
  background-color: transparent !important;
  // padding-top: 0.8rem !important;
  // padding-bottom: 0.8rem !important;
}

.test .rmsc .item-renderer {
  align-items: center !important;
}

.test .custom-multi-select .checkbox input:checked {
  background-color: #0d9e91 !important;
}

.test .custom-multi-select .checkbox input:hover {
  background-color: #0d9e91 !important;
}

.test .rmsc .clear-selected-button svg {
  height: 18px !important;
  width: 18px !important;
}

.test .rmsc .dropdown-container:focus-within {
  border: 0.1rem solid #384a62 !important;
  box-shadow: #384a62 0 0 0 0.1px !important;
}

.test .rmsc .dropdown-search-clear-icon {
}

/* Adjust specificity if needed */
.test .multi-select-container .rmsc .item-renderer input[type='checkbox']:checked {
  background-color: #146c6a !important;
  accent-color: #146c6a !important;
}

.req-red-btn {
  width: 160px !important;
  height: 31px !important;
}

.test .table td {
  padding: 1.75rem 0.75rem !important;
}

.financial-input {
  height: 38px !important;
}

.dark-blue-clr {
  color: #35475e !important;
}

.approve-st {
  border: 2px solid #0d9e91 !important;
  color: #0d9e91 !important;
}

.red-color {
  color: #e46464 !important;
}

.status-w-240 {
  width: 240px !important;
}

.status-w-250 {
  width: 250px !important;
}

.status-w-260 {
  width: 260px !important;
}

.status-w-280 {
  width: 280px !important;
}

.status-w-150 {
  width: 150px !important;
}

.status-w-120 {
  width: 120px !important;
}

.status-w-130 {
  width: 130px !important;
}

.status-w-140 {
  width: 140px !important;
}

.status-w-160 {
  width: 160px !important;
}

.status-w-170 {
  width: 170px !important;
}

.status-w-180 {
  width: 180px !important;
}

.status-w-190 {
  width: 190px !important;
}

.status-w-200 {
  width: 200px !important;
}

.h-31 {
  height: 31px !important;
}

.max-ht-380 {
  max-height: 380px !important ;
  overflow: scroll !important;
}

.tst .css-1xc3v61-indicatorContainer {
  padding: 0px 0px 2px 8px !important;
}

.tst .css-15lsz6c-indicatorContainer {
  padding: 0px 0px 2px 8px !important;
}

.bullet-item {
  height: 4px !important;
  width: 4px !important;
  background-color: #000 !important;
  border-radius: 50% !important;
}

.mytest:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
  // font-size: 11px;
}

.ant-picker-input > input:placeholder-shown {
  // font-size: small;
}

.test .table-responsive {
  overflow-x: unset !important;
}

.me-100px {
  margin-right: 100px;
}

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}

input:focus {
  // outline: none !important;
  border-color: #35475e !important;
  // box-shadow: 0 0 10px #719ECE;
}

.grid-out {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  // align-items: center!important;
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.user-header-style {
  // background-color: #0d9e91  !important;
  box-shadow: 0px 0px 20px 0px #0d9e91 !important;
}

.modal-header-cross {
  margin-top: -20px;
  margin-right: -5px;
}

.user-header-bg {
  background-color: #146c6a !important;
  transform: translate(0px, -84px);
  height: 85px;
  border-bottom: 0px;
  border-radius: 7px 7px 0px 0px;
}

.mytest:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
  font-size: 13px !important;
}
.mytest {
  /* no value in input */
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}
.mytest:focus {
  border-color: #35475e !important;
}

.multi-select-container {
  position: relative;
}

.vendor .react-tel-input .form-control {
  width: -webkit-fill-available !important;
}

.vendor .table.gy-5 td {
  padding: 1.3rem 1.3rem 1.3rem 0.75rem !important;
}

.bullet-div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.bullet-div-sm {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.vendor .react-tel-input {
  width: 50% !important;
}

.vendor2 .react-tel-input {
  width: 100% !important;
}

.manage-list-btn {
  border: 2px solid #146c6a !important;
  color: #146c6a !important;
  width: 130px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.manage-list-btn:hover {
  border: 2px solid #146c6a !important;
  color: #146c6a !important;
  width: 130px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.property .rmsc .dropdown-container {
  width: 160px !important;
}

.property .filter-control {
  height: 35px !important;
  width: 180px !important;
}

.property.no-value .rmsc .dropdown-container {
  background-color: #f5f8fa !important;
}

.property.has-value .rmsc .dropdown-container {
  background-color: #f5f8fa !important;
}

.property .rmsc .select-item.selected {
  background-color: transparent !important;
}

.property .rmsc .dropdown-content .panel-content {
  background-color: #f5f8fa !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.status-w-225 {
  width: 225px !important;
}

.vend-lists {
  box-shadow: lightgrey 0px 1px 6px 0px;
  transform: translate(-6px, -1px);
  max-height: 175px;
  overflow: scroll;
}

.horizontal-scroll-container {
  overflow-x: auto;
  overflow-y: hidden; /* This hides the vertical scroll */
  white-space: nowrap; /* This prevents the content from wrapping to the next line */
}

/* Add this to your CSS file or use a styled-components approach */
.color-swatch {
  width: 30px;
  height: 30px;
  borderradius: 50%;
}

.color-swatch-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.circle-picker {
  width: 100% !important;
}

.property .rmsc .dropdown-heading-dropdown-arrow {
  transform: translate(0px, -3px) !important;
}

.property .rmsc .clear-selected-button  {
  transform: translate(0px, -2px) !important;
}



.no-value .dropdown-heading-value span {
  display: none;
}

.no-value.grpunit .dropdown-heading-value::after {
  content: 'Unit Group...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.dev .dropdown-heading-value::after {
  content: 'Development...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.clust .dropdown-heading-value::after {
  content: 'Cluster...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.floor .dropdown-heading-value::after {
  content: 'Floor...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.occupancy .dropdown-heading-value::after {
  content: 'Occupancy...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.type .dropdown-heading-value::after {
  content: 'Type...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.list .dropdown-heading-value::after {
  content: 'List...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.announce .dropdown-heading-value::after {
  content: 'Status' !important;
  color: #aaa !important;
  font-style: italic !important;
}


.no-value-custom-style .rmsc .dropdown-container {
  width: 160px !important;
  border: 0.1rem solid #bec3cb !important;
  border-radius: 2px !important;
  height: 35px !important;
  background-color: transparent !important;
  // padding-top: 0.8rem !important;
  // padding-bottom: 0.8rem !important;
}


.has-value-custom-style .rmsc .dropdown-container {
  width: 160px !important;
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  height: 35px !important;
  background-color: transparent !important;
  // padding-top: 0.8rem !important;
  // padding-bottom: 0.8rem !important;
}

.no-value-custom-style.requestStatus .dropdown-heading-value::after {
  content: 'Status' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value-custom-style.client .dropdown-heading-value::after {
  content: 'Client' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.tst .rmsc .dropdown-container {
  width: 200px !important;
}

.custom-confirm-button {
  background-color: #35475e !important;
  border: 2px solid #35475e !important;
  color: #fff !important;
  width: 70px !important;
  font-size: 12px !important;
}

.custom-cancel-button {
  background-color: transparent !important;
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 70px !important;
  font-size: 12px !important;
}









