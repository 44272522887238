.requestBox {
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-basis: content;
  padding: 20px 20px;
  text-align: center;
  border: 2px solid #146c6a !important;
  cursor: pointer;
  width: 140px;
}
.requestBoxColor {
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-basis: content;
  padding: 20px 20px;
  text-align: center;
  border: 2px solid #146C6A !important;
  background-color: #146c6a;
  color: #ffffff !important;
  width: 140px;
}

.headTextWhite {
  color: #ffffff !important;
}

.requestBoxOpacity {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 14px;
}

.urgency-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px !important;
  height: 25px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.urgencyStandardStatus {
  background-color: #b0b7c3 !important;
}

.urgencyEmergencyStatus {
  background-color: #d54645 !important;
}

.no-value.mainService .dropdown-heading-value::after {
  content: 'Service...' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value.urgency .dropdown-heading-value::after {
  content: 'Urgency...' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value.development .dropdown-heading-value::after {
  content: 'Development...' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value.client .dropdown-heading-value::after {
  content: 'Client...' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value.subService .dropdown-heading-value::after {
  content: 'Sub Service...' !important;
  color: #aaa !important;
  font-style: italic !important;
}
.no-value.handledBy .dropdown-heading-value::after {
  content: 'Handled By...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.vendor .dropdown-heading-value::after {
  content: 'Vendor...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.no-value.unit .dropdown-heading-value::after {
  content: 'Unit...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.schedule .ant-modal-header {
  border-bottom: 0px solid !important;
}

.schedule .ant-modal-close-x{
  display: none !important;
}

.schedule .ant-modal-footer {
border-top: 0px solid #f0f0f0;
padding-bottom: 5px !important;

}

.schedule .MuiButtonBase-root {
  padding-right: 4px !important;
}

.schedule .MuiInputBase-input {
  border: 0.1rem solid #384a62 !important;
  border-radius: 2px !important;
  border-right: 0px solid !important;
  color: #384a62  !important
}
.schedule .MuiInputBase-input:placeholder-shown{
  border: 0.1rem solid #bec3cb !important;
  border-right: 0px solid !important;
}

.schedule .MuiTextField-root {
  border: 0.1rem solid #bec3cb !important;
}

.btn-fit-content{
  width: fit-content !important;
  height: 31px !important;
}

.btn-fit-content:hover{
  width: fit-content !important;
  height: 31px !important;
}

.status-w-100{
  width: 100px !important
}

.status-w-100:hover{
  width: 100px !important
}

.no-value-custom-style .dropdown-heading-value span {
  display: none;
}

.no-value-custom-style .requestStatus .dropdown-heading-value::after {
  content: 'Status...' !important;
  color: #aaa !important;
  font-style: italic !important;
}

.customCard{
  width:18rem;
  background-color: #ffffff;
  height: auto;
}

.customSeparator{
  background-color: #0d9e91 !important;
  height: 3px;
  width: 100%;
  margin-top: 5px;
}

.ant-picker {
  width: 100%;
  // border-radius: 0.475rem;
  height: 34px;
  border: 0.1rem solid #bec3cb;
  border-radius: 2px;
  padding: 0.4rem 0.4rem;
  background-color: #f5f8fa;
}

.ant-picker.ant-picker-disabled {
  border: 0.1rem solid #bec3d3;
  background-color: #f5f8fa;
}

.ant-picker-dropdown {
  z-index: 1060;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #35475e;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #35475e !important;
}

.ant-picker-today-btn {
  color: #35475e;
}

.green-border-box{
  border-radius: 5px !important;
  border: 0.12rem solid #146c6a !important;
  background: #fff !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}

.jobs-comments-edit {
  /* no value in input */
  border-radius: 5px !important;
  border: 0.1rem solid #bec3cb !important;
  border-radius: 2px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}

.jobs-comments{
  border-radius: 5px !important;
  border: 0.12rem solid #384a62 !important;
  background: #fff !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 13px !important;
}

.fancybox__container{
  z-index: 9999 !important;
}
.multi-select-container .rmsc .dropdown-content .panel-content .item-renderer span {              
  /* Define the width of the element */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
