.mw-1150px {
  min-width: 1150px !important;
}

.mw-1200px {
  min-width: 1200px !important;
}

.green-hollow-btn {
  border: 2px solid #146c6a !important;
  color: #146c6a !important;
  width: 110px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.green-hollow-btn:hover {
  border: 2px solid #146c6a !important;
  color: #146c6a !important;
  width: 110px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.green-submit-btn {
  border: 1px solid #146c6a !important;
  color: white !important;
  background-color: #146c6a !important;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.green-submit-btn:hover {
  border: 1px solid #146c6a;
  color: white;
  background-color: #146c6a;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.grey-submit-btn {
  border: 1px solid #48474d!important;
  color: white !important;
  background-color: #48474d!important;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.grey-submit-btn:hover {
  border: 1px solid #48474d;
  color: white;
  background-color: #48474d;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}



.green-submit-btn:disabled,
.green-submit-btn[disabled] {
  // border: 1px solid #146c6a;
  // color: white;
  // background-color: #146c6a;
  cursor: not-allowed !important;
}

.tenancy-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px !important;
  height: 25px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px !important;
  height: 25px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.status-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px !important;
  height: 25px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.renew-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px !important;
  height: 25px !important;
  border-radius: 14px !important;
  opacity: 0.1px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.total-bar {
  background-color: #146c6a !important;
}
.booked-bar {
  background-color: #0099cc !important;
}
.booked-color {
  color: #0099cc !important;
}

.active-bar {
  background-color: #00b8a9 !important;
}
.active-color {
  color: #00b8a9 !important;
}

.expiring-bar {
  background-color: #f38513 !important;
}
.expiring-color {
  color: #f38513 !important;
}

.expired-bar {
  background-color: #e46464 !important;
}
.expired-color {
  color: #e46464 !important;
}

.no-st-bar {
  background-color: #b0b7c2 !important;
}
.no-st-color {
  color: #b0b7c2 !important;
}

.select-btn {
  border: 1px solid #0d9e91 !important;
  color: white !important;
  background-color: #0d9e91 !important;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}
.select-btn:hover {
  border: 1px solid #0d9e91;
  color: white;
  background-color: #0d9e91;
  width: 110px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.draft-color {
  color: #0099cc !important;
}

.req-bar {
  background-color: dimgray !important;
}

.draft-st {
  border: 2px solid #0099cc !important;
  color: #0099cc !important;
}

.create-st {
  border: 2px solid #146c6a !important;
  color: #146c6a !important;
}

.not-saved {
  border: 2px solid #b0b7c2 !important;
  color: #b0b7c2 !important;
}

.cancelled-st {
  border: 2px solid #d54645 !important;
  color: #d54645 !important;
}

.renewed-st {
  border: 2px solid #0d9e91 !important;
  color: #0d9e91 !important;
}

.requested-st {
  border: 2px solid #f38513 !important;
  color: #f38513 !important;
}

.min-card {
  min-height: 310px !important;
}

.card-res {
  min-height: unset !important;
}

.tb-bg {
  height: 50px;
  width: 50px;
  border-radius: 50%; /* Makes the div circular */
  background: rgb(12, 139, 128);
  background: radial-gradient(
    circle,
    rgba(13, 142, 130, 1) 10%,
    rgba(14, 170, 155, 1) 50%,
    rgba(20, 227, 209, 1) 90%
  );
  background-blend-mode: multiply;
}

.tb-bg-active {
  height: 50px;
  width: 50px;
  background: radial-gradient(
    circle,
    rgba(10, 101, 99, 1) 10%,
    rgba(12, 150, 147, 1) 50%,
    rgba(11, 179, 174, 1) 90%
  );
  border-radius: 50%;
  color: #146c6a;
  // background-color: #146c6a;
}

.tb-active {
  color: #146c6a;
  text-decoration: underline !important;
  font-weight: 800;
}

.connector {
  border-top: 2px solid #0d9e91;
  background-color: black;
  width: 72% !important;
}

.labl-gry {
  color: dimgrey;
  // font-size: 12px;
}

.blue-btn {
  border: 1px solid #35475e !important;
  color: white !important;
  background-color: #35475e !important;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.blue-btn:hover {
  border: 1px solid #35475e;
  color: white;
  background-color: #35475e;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.blue-hollow-btn {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  background-color: white !important;
  width: 100px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.tenancy-control {
  border-radius: 0.25rem !important;
  padding: 5px 10px !important;
  height: 32px !important;
}

.test .form-check-custom.form-check-solid .form-check-input {
  border-color: #146c6a !important ;
}

.test .form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #146c6a !important;
  background-image: var(--kt-form-check-input-checked-bg-image) !important;
}

.br-arr {
  border: 2px solid #146c6a;
  height: 25px;
  width: 40px;
  border-radius: 5px;
}

.red-hollow-btn {
  border: 2px solid #d54645 !important;
  color: #d54645 !important;
  width: 110px;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.term-btn {
  border: 2px solid #d54645 !important;
  color: white !important;
  background-color: #d54645 !important;
  width: 170px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.renew-btn {
  border: 2px solid #146c6a !important;
  color: white !important;
  background-color: #146c6a !important;
  width: 170px !important;
  // max-width: 220px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.filter-control {
  border-radius: 0.1rem !important;
  padding: 3px 10px !important;
  height: 38px !important;
  border-radius: 0px !important;
}

.bullet-ic {
  height: 5px !important;
  width: 5px !important;
  background-color: #000 !important;
  border-radius: 50% !important;
}

.tenancy-green-card {
  color: #ffff;
  padding-bottom: 5px;
  height: 56px;
  width: 128px;
  font-weight: 800px;
  border-radius: 2px !important;
  cursor: pointer;
  // margin: 0px auto;
  background-color: #0d9e91;
}

.tenancy-active-card {
  color: #ffff;
  padding-bottom: 5px;
  height: 56px;
  width: 128px;
  font-weight: 800px;
  border-radius: 2px !important;
  cursor: pointer;
  // margin: 0px auto;
  background-color: #35475e;
}

.active-filter {
  height: 2px;
  width: 115px;
  margin: 10px 6px 0 6px;
  background-color: #35475e;
}

.upload-btn {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 135px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.upload-btn:hover {
  border: 2px solid #35475e !important;
  color: #35475e !important;
  width: 135px !important;
  font-size: 11px !important;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.ok-div {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #146c6a;
}

.xyz .MuiPaper-root {
  border: 0.1rem solid #bec3cb !important;
  background-color: #f5f8fa !important;

  color: var(--kt-input-solid-color);
  transition: color 0.2s ease;
  border-radius: 0.25rem !important;
  padding: 5px 5px !important;
  height: 35px !important;
  box-shadow: none !important;
}
.xyz .MuiPaper-root:focus {
  border-color: #35475e !important;
}

.xyz .MuiInputBase-input {
  padding: 0px !important;
  color: #384a62 !important;
}

.test .msg_title {
  background-color: #146c6a !important;
}

.xyz .form-check-input {
  border-color: #384a62 !important;
}

.xyz .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #384a62 !important;
}

.progress-filter {
  width: 100%;
  height: 20px;
  background-color: #146c6a !important ;
  opacity: 0;
  position: relative;
  z-index: 999;
}

.tenancy .form-check-custom.form-check-solid .form-check-input {
  border-color: #146c6a !important ;
}

.tenancy .form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #146c6a !important;
  border-color: #146c6a !important;
  background-image: none !important;
}

.term-width {
  width: fit-content !important;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  height: 10px !important;
  width: 8px !important; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  // width: 50px !important
}

::-webkit-scrollbar-thumb {
  background-color: grey !important; /* Thumb color */
  border-radius: 10px; /* Rounded corners on the thumb */
  // width: 30px !important
}

.test .page-item.active .page-link {
  background-color: #35475e !important;
}

.test .react-tel-input .form-control {
  width: 100% !important;
}

.req-red-btn {
  width: 160px !important;
  height: 31px !important;
}

.test .table td {
  padding: 1.75rem 0.75rem !important;
}

.view-btn {
  background-color: #0d9e91 !important;
  width: fit-content !important;
  color: #fff !important;
}

.financial-input {
  height: 38px !important;
}

.mask-toolbar {
  height: 50px;
  width: 100%;
  transform: translate(10px, -88px);
  background-color: transparent;
  z-index: 1000;
  cursor: not-allowed;
}

.done-btn {
  width: 320px;
  font-size: 1.2rem;
  //  paddingRight: '55px',
  border-radius: 7px;
  background-color: #35475e;
  box-shadow: 0px 0px 20px 0px #516176;
}

.done-btn:hover {
  width: 320px;
  font-size: 1.2rem;
  //  paddingRight: '55px',
  border-radius: 7px;
  background-color: #35475e;
  box-shadow: 0px 0px 20px 0px #516176;
}

.done-btn:disabled {
  width: 320px;
  font-size: 1.2rem;
  //  paddingRight: '55px',
  border-radius: 7px;
  background-color: #35475e;
  box-shadow: 0px 0px 20px 0px #516176;
}

.CalendarDay__selected_span {
  color: #fff !important;
  background: #007a59 !important;
}

.DateInput_input {
  font-size: 14px !important;
  padding: 4px 9px 4px !important;
}

.DateInput_fang {
  top: 70px !important;
}

.DayPicker_transitionContainer__horizontal {
  margin-top: 16px !important;
}

.CalendarDay__default {
  padding: 0px !important;
}

.day-render {
  margin-top: -19px;
  height: 38px;
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none !important;
}

.CalendarDay__selected {
  background-color: #007a59 !important;
  color: white !important;
}

.CalendarDay__selected_span {
  background-color: #e0f5f0 !important;
  color: #000 !important;
}

.DateInput_input {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  color: #5e6278 !important;
}

// .DateInput_input:placeholder-shown {
//   // border: 0.1rem solid #bec3cb !important;
//   border-color: #bec3cb  !important;
// }

// .DateInput_input {
//   /* no value in input */
//   // border: 0.1rem solid #384a62 !important;
//   border-color: #35475e !important;
// }
// .DateInput_input:focus {
//   border-color: #35475e !important;
// }

.custom-date-range-picker .DateInput_input {
  position: relative !important;
}

.custom-date-range-picker .DateInput_input > div:last-child {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 10px !important; /* Adjust this value as needed */
}

.custom-date-range-picker .DateRangePickerInput_calendarIcon {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-55%) !important;
  right: 0px !important; /* Adjust this value as needed */
  z-index: 1;
}

.send-back-btn {
  border: 1px solid #ff9a1e !important;
  color: white !important;
  background-color: #ff9a1e !important;
  width: 115px;
  font-size: 11px;
  height: 31px !important;
  display: flex !important;
  align-items: center !important;
}

.dark-blue-clr {
  color: #35475e !important;
}

.send-back-color {
  color: #ff9a1e !important;
}

.pend-st {
  border: 2px solid dimgray !important;
  color: dimgray !important;
}

.approve-st {
  border: 2px solid #0d9e91 !important;
  color: #0d9e91 !important;
}

.red-color {
  color: #e46464 !important;
}

.pending-approval-st {
  border: 2px solid #ff9a1e !important;
  color: #ff9a1e !important;
}

.exception-btn {
  height: 38px !important;
}

.exception-btn:hover {
  height: 38px !important;
}

.backdrop {
  //   position: fixed;
  //   top: 138px;
  //   left: 200px;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  //   z-index: 999; /* Make sure it's on top of other elements */
}

.ten-renew-term {
  width: 170px !important;
}

.status-w-110{
  width: 110px !important;
}

.status-w-240 {
  width: 240px !important;
}

.status-w-250 {
  width: 250px !important;
}

.status-w-260 {
  width: 260px !important;
}

.status-w-280 {
  width: 280px !important;
}

.status-w-150 {
  width: 150px !important;
}

.status-w-130 {
  width: 130px !important;
}

.status-w-140 {
  width: 140px !important;
}

.status-w-160 {
  width: 160px !important;
}

.status-w-170{
  width: 170px !important;
}

.status-w-180 {
  width: 180px !important;
}

.status-w-200 {
  width: 200px !important;
}

.h-31{
  height: 31px !important
}

.tenant-approve-st {
  border: 2px solid #00b9a9 !important;
  color: #00b9a9 !important;
}

.max-ht-380 {
  max-height: 380px !important ;
  overflow: scroll !important;
}

.tst .css-1xc3v61-indicatorContainer {
  padding: 0px 0px 2px 8px !important
}

.tst .css-15lsz6c-indicatorContainer {
  padding: 0px 0px 2px 8px !important
}

.bullet-item {
  height: 4px !important;
  width: 4px !important;
  background-color: #000 !important;
  border-radius: 50% !important;
}

.mytest:placeholder-shown {
  border: 0.1rem solid #bec3cb !important;
   font-size: 13px;
}

.ant-picker-input > input:placeholder-shown {
  // font-size: small;

}

.test .table-responsive {
   overflow-x: unset !important;
}

