.table.gy-5 td{
  padding-bottom: 0.7em
}

.message {
  position: fixed;
}

.card {
  box-shadow: 0px 0px 20px 0px rgb(18 32 78 / 20%);
}

.form-check-input:checked{
  background-color: #007a59 !important;
  border-color: #007a59 !important;
}

.form-check-input{
  border: 0 !important;
  background-color: #eff2f5 !important;
  height: 1.55rem !important;
   width: 1.55rem !important;
}

.arrow_approval_request {
  position: absolute !important;
  z-index: 1 !important;
  bottom: -1.6rem !important;
  left: 50% !important;
  top: unset !important;
}

.tooltip > div.tooltip-inner {
  background-color: darkgray !important;
  color: white !important;
  // border: 1px solid gray;
  font-weight: bold;
  margin-bottom: 10px;
}

.abc {
  animation: beat 5s infinite 7s;
  /* animation-delay: 2s; */
  transform-origin: center;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #007a59 !important;
}

.right-card-approve {
  // margin-right: 2.5rem !important; */
  margin-left: 2.5rem !important;
}

.show-line-sign {
  display: none;
}

/* Heart beat animation */
@keyframes beat {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
    transform: scale(1.16);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
    transform: scale(1.18);
  }
}

@media print {
  /* styles here */
  .written-sign {
    display: none;
    opacity: 0 !important;
  }

  .e-sign {
    display: none;
    opacity: 0 !important;
  }
  .back-btn {
    display: none !important;
  }
  .approval-flow {
    display: none !important;
  }

  .toggle-btn {
    display: none !important;
  }

  .print-approve {
    z-index: 1060;
  }

  .app_header {
    display: none !important;
  }

  .app-navbar {
    display: none !important;
  }

  .app_container {
    display: none !important;
  }

  #kt_app_sidebar_mobile_toggle {
    display: none !important;
  }
  #kt_app_header {
    display: none !important;
  }

  .right-card-approve {
    // margin-right: 2.5rem !important; */
    margin-left: 0 !important;
  }

  .app-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .scrollop {
    display: none !important;
  }

  .media-comments {
    display: none !important;
  }

  .modal-backdrop.show {
    opacity: 0;
  }
  .show-line-sign {
    display: block;
  }
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  width: 100%;
  overflow: hidden;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 2.3em !important;
  box-sizing: border-box !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  line-height: 1.59;
  padding: 10px 11px 10px !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  // border: 1px solid #D9D9E1!important;
  border-right: none !important;
  border-radius: 2px !important;
  transition: border 0.3s, box-shadow 0.3s;
  color: darkgray !important;
}

.MuiSvgIcon-root {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.25) !important;
  height: 17px !important;
  width: 17px !important;
  box-shadow: none !important;
}

.MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  transition: border 0.3s, box-shadow 0.3s !important;
  color: black !important;
  padding: 4px 11px 4px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  height: 2em !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
 
  border-radius: 2px !important;
  transition: border 0.3s, box-shadow 0.3s !important;
  color: black !important;
 

}

.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected{
  background-color: #007a59 !important;

}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  color: #fff;
    background:  #007a59 !important;
  
}
